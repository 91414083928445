import React, {useState, useEffect, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IErrorUIProps } from '../../utils/types';
import SEO from './SEO'
import body from '../../helpers/body';

const ErrorUI = ({ error, reset }: Partial<IErrorUIProps>) => {

    const navigate = useNavigate()

    useEffect(() => {

        body.changeBackground('bg-mint');

    }, [])

    const goBack = (e: any): void => {
        if(e) { e.preventDefault() }
        
        if(reset){
            reset()
        }else{
            navigate(-1)
        }
    }

    return (
        <>
            <SEO pageTitle="Vacepay - Error" type='page' />

            <div className="not-found">

                <div className="container ui-text-center">

                    <img src="../../../images/icons/icon@wifi.svg" width="100px" alt="not-found" />
                    <h3 className="mrgt1 font-dmmedium fs-27 onwhite mrgb">Oops! There was an error.</h3>
                    <p className="font-dm fs-15 onvace-bright mrgb2">We're sorry. Please go back and refresh the page.</p>
                    <Link to="" onClick={(e) => goBack(e)} className="btn md bgd-vorange stretch-lg onwhite font-dmbold fs-15">Go Back </Link>
                    
                </div>

            </div>
        </>
    )
  
}

export default ErrorUI