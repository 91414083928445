import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from './helpers/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorUI from './components/global/ErrorUI';

import DashboardLayout from './components/layouts/Dashboard';

import ResourceState from './context/resource/resourceState';
import UserState from './context/user/userState';
import VaceState from './context/vace/vaceState';


// componets --test
const Homepage = React.lazy(() => import('./pages/Home'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const InvitePage = React.lazy(() => import('./pages/invite'));
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'));
const ActivateAccount = React.lazy(() => import('./pages/auth/ActivateAccount'));
const NetworkUI = React.lazy(() => import('./components/global/NetworkUI'));

const DashHome = React.lazy(() => import('./pages/dashboard/Dashboard'));
const Users = React.lazy(() => import('./pages/dashboard/users/Users'));
const AuditsPage = React.lazy(() => import('./pages/dashboard/account/Audits'));
const Admins = React.lazy(() => import('./pages/dashboard/users/Admins'));
const NewUser = React.lazy(() => import('./pages/dashboard/users/NewUser'));
const Permissions = React.lazy(() => import('./pages/dashboard/users/Permissions'));
const Compliance = React.lazy(() => import('./pages/dashboard/compliance/Compliance'));
const KYBPage = React.lazy(() => import('./pages/dashboard/compliance/KYBPage'));
const KYCPage = React.lazy(() => import('./pages/dashboard/compliance/KYCPage'));

const CorporateUsers = React.lazy(() => import('./pages/dashboard/users/corporates/Corporates'));
const CorporateDetails = React.lazy(() => import('./pages/dashboard/users/corporates/CorporateDetails'));
const CorporateSettings = React.lazy(() => import('./pages/dashboard/users/corporates/CorporateSettings'));

const Transactions = React.lazy(() => import('./pages/dashboard/payments/transactions/Transactions'));
const TransactionDetails = React.lazy(() => import('./pages/dashboard/payments/transactions/Details'))
const Chargebacks = React.lazy(() => import('./pages/dashboard/payments/chargebacks/Chargebacks'));
const ChargebackDetails = React.lazy(() => import('./pages/dashboard/payments/chargebacks/Details'))
const PaymentLinks = React.lazy(() => import('./pages/dashboard/payments/payment-links/PaymentLinks'))
const PaymentLinkDetails = React.lazy(() => import('./pages/dashboard/payments/payment-links/LinkDetails'))
const Products = React.lazy(() => import('./pages/dashboard/products/Products'))
const ProductDetails = React.lazy(() => import('./pages/dashboard/products/ProductDetails'))
const Settlements = React.lazy(() => import('./pages/dashboard/payments/settlements/Settlements'))
const SettlementTimelines = React.lazy(() => import('./pages/dashboard/payments/settlements/Timelines'))
const SettlementDetails = React.lazy(() => import('./pages/dashboard/payments/settlements/SettlementDetails'))
const Refunds = React.lazy(() => import('./pages/dashboard/payments/refunds/Refunds'))
const RefundDetails = React.lazy(() => import('./pages/dashboard/payments/refunds/RefundDetails'))

// settings
const GeneralSettings = React.lazy(() => import('./pages/dashboard/settings/General'))
const ProviderSettings = React.lazy(() => import('./pages/dashboard/settings/Providers'))

// finance
const WalletPage = React.lazy(() => import('./pages/dashboard/finance/Wallet'))

// invoices
const Invoices = React.lazy(() => import('./pages/dashboard/invoices/Invoices'))
const InvoiceDetails = React.lazy(() => import('./pages/dashboard/invoices/InvoiceDetails'))
const NewInvoice = React.lazy(() => import('./pages/dashboard/invoices/NewInvoice'))
const InvoiceTransactions = React.lazy(() => import('./pages/dashboard/invoices/InvoiceTransactions'));

// checkout
const InvoicePreview = React.lazy(() => import('./pages/checkout/InvoicePreview'))

const App = () => {

  const errorHandler = (err: any, info: any) => {
    console.log(err, 'logged error');
    console.log(info, 'logged error info');
  }

  return (

    <Router>

      <UserState>

        <VaceState>

          <ResourceState>

              <Suspense fallback={loader.MainLoader()}>

                <ErrorBoundary FallbackComponent={ErrorUI} onReset={() => { window.location.reload() }} onError={errorHandler}>

                    <Routes>

                      <Route path='/home' element={<Login />} />
                      <Route path='/' element={<Login />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/invite/:token' element={<InvitePage />} />
                      <Route path='/reset-password' element={<ResetPassword />} />
                      <Route path='/activate-account/:token' element={<ActivateAccount />} />
                      <Route path='/no-network' element={<NetworkUI />} />
                      <Route path='/invoice/:code' element={<InvoicePreview />} />

                      <Route path='/dashboard/account/profile' element={<DashboardLayout Component={AuditsPage} pageTitle="Profile" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/account/audits' element={<DashboardLayout Component={AuditsPage} pageTitle="Audits" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard' element={<DashboardLayout Component={DashHome} pageTitle="Dashboard" showBack={false} collapsed={false} />} />
                      <Route path='/dashboard/users' element={<DashboardLayout Component={Users} pageTitle="All Users" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/admins' element={<DashboardLayout Component={Admins} pageTitle="All Administrators" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/new' element={<DashboardLayout Component={NewUser} pageTitle="Add User" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/permissions' element={<DashboardLayout Component={Permissions} pageTitle="User Permissions" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/compliance' element={<DashboardLayout Component={Compliance} pageTitle="Compliance" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/compliance/kyb/:id' element={<DashboardLayout Component={KYBPage} pageTitle="KYB Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/compliance/kyc/:id' element={<DashboardLayout Component={KYCPage} pageTitle="KYB Details" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/users/corporates' element={<DashboardLayout Component={CorporateUsers} pageTitle="Corporates" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/corporates/:id' element={<DashboardLayout Component={CorporateDetails} pageTitle="Corporate Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/users/corporates/settings/:id' element={<DashboardLayout Component={CorporateSettings} pageTitle="Corporate Settings" showBack={true} collapsed={false} />} />
                      
                      <Route path='/dashboard/transactions' element={<DashboardLayout Component={Transactions} pageTitle="Transactions" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/transactions/:id' element={<DashboardLayout Component={TransactionDetails} pageTitle="Transactions" showBack={true} collapsed={false} />} />
                      
                      <Route path='/dashboard/chargebacks' element={<DashboardLayout Component={Chargebacks} pageTitle="Chargebacks" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/chargebacks/:id' element={<DashboardLayout Component={ChargebackDetails} pageTitle="Chargebacks" showBack={true} collapsed={false} />} />
                     
                      <Route path='/dashboard/settlements' element={<DashboardLayout Component={Settlements} pageTitle="Settlements" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settlements/timelines' element={<DashboardLayout Component={SettlementTimelines} pageTitle="Settlement Timelines" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settlements/:id' element={<DashboardLayout Component={SettlementDetails} pageTitle="Settlement Details" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/payment-links' element={<DashboardLayout Component={PaymentLinks} pageTitle="Payment Links" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/payment-links/:id' element={<DashboardLayout Component={PaymentLinkDetails} pageTitle="Link Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/products' element={<DashboardLayout Component={Products} pageTitle="Products" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/products/:id' element={<DashboardLayout Component={ProductDetails} pageTitle="Product Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/refunds' element={<DashboardLayout Component={Refunds} pageTitle="Refunds" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/refunds/:id' element={<DashboardLayout Component={RefundDetails} pageTitle="Refund Details" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/settings/general' element={<DashboardLayout Component={GeneralSettings} pageTitle="System Settings" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settings/providers' element={<DashboardLayout Component={ProviderSettings} pageTitle="Provider Settings" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/finance/wallet' element={<DashboardLayout Component={WalletPage} pageTitle="Wallet & Revenue" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/invoices' element={<DashboardLayout Component={Invoices} pageTitle="Invoices" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/invoices/:id' element={<DashboardLayout Component={InvoiceDetails} pageTitle="Invoice Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/invoices/transactions/:id' element={<DashboardLayout Component={InvoiceTransactions} pageTitle="Invoice Transactions" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/invoices/new' element={<DashboardLayout Component={NewInvoice} pageTitle="New Invoice" showBack={true} collapsed={false} />} />
                      
                    </Routes>

                </ErrorBoundary>

              </Suspense>

          </ResourceState>

        </VaceState>

      </UserState>

    </Router>

  )

}

export default App;