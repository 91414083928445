import React from 'react'
import { IEmptyProps } from '../../utils/types'

const EmptyBox = ({ children, bgColor, size, className }: Partial<IEmptyProps>) => {
    return (
        <>

            <div className={`empty-box ${className ? className : ''} ${size ? size : 'sm'}`} style={{ backgroundColor: `${ bgColor ? bgColor : '' }` }}>

                <div className="ui-text-center">
                    <div className="row">
                        <div className="col-md-10 mx-auto ui-text-center">
                            { children }
                        </div>
                    </div>
                </div>

            </div> 
            
        </>
    )
}

export default EmptyBox