import React, { useState, useEffect, useContext } from 'react'
import { IDLayoutProps, IResourceContext, IUserContext, IVaceContext } from '../../utils/types'

import SideBar from '../partials/Sidebar'
import TopBar from '../partials/Topbar'
import UserContext from '../../context/user/userContext'
import VaceContext from '../../context/vace/vaceContext'
import ResourceContext from '../../context/resource/resourceContext'
import { NotPermittedType } from '../../utils/enums.util'
import EmptyBox from '../partials/Empty'


const DashboardLayout = ({ Component, pageTitle, showBack, collapsed }: Partial<IDLayoutProps>) => {

    const [barCollapsed, setBarCollapsed] = useState<boolean | any>(false);

    const userContext = useContext<IUserContext>(UserContext)
    const vaceContext = useContext<IVaceContext>(VaceContext)
    const resourceContext = useContext<IResourceContext>(ResourceContext)
    const [permit, setPermit] = useState<{ code: any, message: any }>({
        code: 200,
        message: ''
    })

    useEffect(() => {
        setBarCollapsed(collapsed);
    }, [])

    const expandCollapse = (e: any): void => {

        if (e) { e.preventDefault() }

        const sidebar = document.getElementById('ui-sidebar');
        const dashBody = document.getElementById('ui-dashboard-body');
        const sidebarPrimary = document.getElementById('ui-sidebar-primary');
        const headPrimary = document.getElementById('ui-sidebar-primary-header');
        const topbar = document.getElementById('ui-dashboard-topbar');

        if (sidebar && dashBody && sidebarPrimary && topbar) {

            //sidebar
            if (sidebar.classList.contains('sdbr--cllps')) {
                sidebar?.classList.remove('sdbr--cllps');
                sidebar?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                sidebar?.classList.remove('sdbr--open');
                sidebar?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

            //dashboard body
            if (dashBody.classList.contains('sdbr--cllps')) {
                dashBody?.classList.remove('sdbr--cllps');
                dashBody?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                dashBody?.classList.remove('sdbr--open');
                dashBody?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

            //sidebar primary
            if (sidebarPrimary.classList.contains('sdbr--cllps')) {
                sidebarPrimary?.classList.remove('sdbr--cllps');
                sidebarPrimary?.classList.add('sdbr--open');
                headPrimary?.classList.remove('sdbr--cllps');
                headPrimary?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                sidebarPrimary?.classList.remove('sdbr--open');
                sidebarPrimary?.classList.add('sdbr--cllps');
                headPrimary?.classList.remove('sdbr--open');
                headPrimary?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

            //topbar
            if (topbar.classList.contains('sdbr--cllps')) {
                topbar?.classList.remove('sdbr--cllps');
                topbar?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                topbar?.classList.remove('sdbr--open');
                topbar?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

        }

    }

    return (
        <>
            <SideBar expandSidebar={expandCollapse} barCollapsed={barCollapsed} collapsed={collapsed} />

            <main id="ui-dashboard-body" className={`ui-dashboard-body ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'} d-body`}>

                <TopBar barType="admin" expandFunc={expandCollapse} barCollapsed={barCollapsed} collapsed={collapsed} showBack={showBack} pageTitle={pageTitle} isFixed={true} />

                <div className="ui-body-content">
                    <div className="ui-body-content-inner">

                        {
                            (
                                (vaceContext.response.permit && vaceContext.response.permit.code === NotPermittedType.STATUS_CODE &&
                                    vaceContext.response.permit.message === NotPermittedType.MESSAGE) ||
                                (userContext.response.permit && userContext.response.permit.code === NotPermittedType.STATUS_CODE &&
                                    userContext.response.permit.message === NotPermittedType.MESSAGE)
                            ) &&
                            <>
                                <EmptyBox bgColor='#161A29' size='md' className='mrgt3' >

                                    <span className='vp-icon vace-users trio'>
                                        <i className='path1 fs-25'></i>
                                        <i className='path2 fs-25'></i>
                                        <i className='path3 fs-25'></i>
                                    </span>

                                    <div className='font-dm mrgb1 fs-14 ui-line-height mrgt1 pdr2 pdl2' style={{ color: '#a4afd9' }}>You are not permitted to access this page. Contact support or admin</div>

                                </EmptyBox>
                            </>
                        }

                        {
                            !vaceContext.response.permit && !userContext.response.permit &&
                            <Component collapsed={barCollapsed} />
                        }


                    </div>
                </div>


            </main >
        </>
    )

}

export default DashboardLayout